<template>
  <div>
    <a-radio-group
      @change="handleSelectView"
      :value="selectView"
      button-style="solid"
      size="large"
      class="mb-5"
    >
      <a-radio-button value="masuk">{{
        this.textRadio.masuk
      }}</a-radio-button>
      <a-radio-button value="keluar">{{textRadio.keluar}}</a-radio-button>
    </a-radio-group>
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-item label="Tanggal">
          <a-date-picker v-if="isDetailRekapKehadiran" size="large" v-model="detilAbsensi.tanggal" @change="(date) => onDateChange(date)" />
          <a-date-picker v-else format="DD MMM YYYY" size="large" v-model="logDate" disabled />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="Jam Absensi">
          <div v-if="isDetailRekapKehadiran">
            <a-time-picker  size="large" v-model="detilAbsensi['jam_' + selectView]" @change="(e) => onTimeChange(`jam_${selectView}`, e)" />
          </div>
          <div v-else>
            <a-input size="large" v-model="detilAbsensi['jam_' + selectView]" disabled />
          </div>
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item label="Browser">
          <a-input size="large" v-model="detilAbsensi['browser_' + selectView]" @change="(e) => handleChange(`browser_${selectView}`, e.target.value)" :disabled="!isDetailRekapKehadiran" />
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item label="OS">
          <a-input size="large" v-model="detilAbsensi['os_' + selectView]" @change="(e) => handleChange(`os_${selectView}`, e.target.value)" :disabled="!isDetailRekapKehadiran" />
        </a-form-item>
      </a-col>
      <a-col :span="8">
        <a-form-item label="Device">
          <a-input size="large" v-model="detilAbsensi['device_' + selectView]" @change="(e) => handleChange(`device_${selectView}`, e.target.value)" :disabled="!isDetailRekapKehadiran" />
          <!-- <a-input v-else size="large" v-model="detilAbsensi['device_' + selectView]" disabled /> -->
        </a-form-item>
      </a-col>
      <!-- <a-col :span="12">
        <a-form-item label="Status Pengajuan">
          <a-select class="w-100" size="large" v-model="detilAbsensi.status_pengajuan" :disabled="!isRejected">
            <a-select-option value="menunggu">Menunggu</a-select-option>
            <a-select-option value="tolak">Ditolak</a-select-option>
            <a-select-option value="terima">Diterima</a-select-option>
          </a-select>
        </a-form-item>
      </a-col> -->
      <a-col :span="24">
        <a-form-item label="I.P. Address">
          <a-input size="large" v-model="detilAbsensi['ip_' + selectView]" @change="(e) => handleChange(`ip_${selectView}`, e.target.value)" :disabled="!isDetailRekapKehadiran" />
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="Lokasi">
          <a-input size="large" v-model="detilAbsensi['lokasi_' + selectView]" @change="(e) => handleChange(`lokasi_${selectView}`, e.target.value)" :disabled="!isDetailRekapKehadiran"/>
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <!-- <a-form-item v-if="isDetailRekapKehadiran" label="Foto">
          <div>
            <a-upload-dragger
              v-decorator="[
                'dragger',
                {
                  valuePropName: 'fileList',
                  getValueFromEvent: normFile,
                },
              ]"
              name="files"
              action="/upload.do"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">
                Klik atau seret file ke area ini untuk mengupload
              </p>
              <p class="ant-upload-hint">
                Hanya support untuk satu file!
              </p>
            </a-upload-dragger>
          </div>
        </a-form-item>
        <a-form-item v-else label="Foto"> -->
        <a-form-item label="Foto">
          <template v-if="detilAbsensi['foto_' + selectView] && detilAbsensi['foto_' + selectView] !== '-'">
            <img style="width:100%;" :src="apiUrl + '/' + detilAbsensi['foto_' + selectView]" :alt="'bukti foto ' + detilAbsensi.name">
          </template>
          <EmptyState
            v-else
            heading="No Data"
            description="You have no assignment in progress"
          />
        </a-form-item>
      </a-col>
    </a-row>
    <template v-if="isDetailRekapKehadiran">
      <a-button size="large" key="cancel" @click.prevent="cancelChangeDetilLog">
        {{ tipeDetailRekapKehadiran === 'edit' ? 'Batal' : 'Kembali' }}
      </a-button>
      <a-button size="large" key="ok" type="primary" @click.prevent="changeDetilLog(tipeDetailRekapKehadiran === 'edit' ? 'edit' : 'add')">
        {{ tipeDetailRekapKehadiran === 'edit' ? 'Ubah' : 'Tambah' }}
      </a-button>
    </template>
  </div>
</template>

<script>
import config from '@/config.js'
import moment from 'moment'
const EmptyState = () => import('@/components/app/EmptyState')

export default {
  props: ['data', 'logDate', 'tipeDetailRekapKehadiran', 'isDetailRekapKehadiran'],
  watch: {
    'data.name'(newVal) {
      if (newVal) {
        // this.detilAbsensi = { ...this.data }
        this.detilAbsensi = this.checkAbsen(this.data)
        // console.log(this.detilAbsensi)
      }
    },
  },
  components: {
    EmptyState,
  },
  mounted() {
    this.detilAbsensi = this.checkAbsen(this.data)
    this.newData = this.checkAbsen(this.data)
  },
  data() {
    return {
      newData: {},
      detilAbsensi: {},
      selectView: 'masuk',
      textRadio: {
        masuk: 'Masuk',
        keluar: 'Keluar',
      },
      apiUrl: config.apiUrl,
      moment,
    }
  },
  methods: {
    // normFile(e) {
    //   console.log('Upload event:', e)
    //   if (Array.isArray(e)) {
    //     return e
    //   }
    //   return e && e.fileList
    // },
    handleSelectView(e) {
      // console.log(this.selectView, e)
      this.selectView = e.target.value
      this.detilAbsensi = this.checkAbsen(this.data)
    },
    clearAllData () {
      const newData = {}
      newData.tanggal = null
      const arrSelectView = ['masuk', 'keluar']
      for (const i in arrSelectView) {
        newData['jam_' + arrSelectView[i]] = null
        newData['browser_' + arrSelectView[i]] = null
        newData['os_' + arrSelectView[i]] = null
        newData['device_' + arrSelectView[i]] = null
        newData['lokasi_' + arrSelectView[i]] = null
        newData['ip_' + arrSelectView[i]] = null
        newData['foto_' + arrSelectView[i]] = null
      }
      return newData
    },
    checkAbsen(data) {
      let newData = {}
      if (this.isDetailRekapKehadiran && this.tipeDetailRekapKehadiran === 'add') {
        newData = this.clearAllData()
        return newData
      } else {
        if (!data.id_absen) {
          newData = {
            ...data,
          }
          newData['jam_' + this.selectView] = '-'
          newData['browser_' + this.selectView] = '-'
          newData['os_' + this.selectView] = '-'
          newData['device_' + this.selectView] = '-'
          newData['lokasi_' + this.selectView] = '-'
          newData['ip_' + this.selectView] = '-'
          newData['foto_' + this.selectView] = '-'
          return newData
        }
        for (const key in data) {
          newData[key] = data[key] || '-'
        }
      }
      return newData
    },
    onDateChange(date) {
      const tanggal = date._d
      const formatTanggal = moment(tanggal).format('YYYY-MM-DD')
      console.log('formatTanggal :>> ', formatTanggal)
      this.newData.tanggal = formatTanggal
    },
    onTimeChange(key, time) {
      // this.newData[key] = moment(time).format('HH:mm:ss')
      if (time !== null) {
        this.newData[key] = moment(time._d).format('HH:mm:ss')
      } else {
        this.newData[key] = null
      }
    },
    handleChange(key, value) {
      this.newData[key] = value || '-'
    },
    changeDetilLog(status) {
      const objNewData = { ...this.newData }
      console.log('objNewData :>> ', objNewData)
      if (objNewData.tanggal) {
        const tipeTanggal = typeof objNewData.tanggal
        if (tipeTanggal === 'object') {
          objNewData.tanggal = moment(objNewData.tanggal).format('YYYY-MM-DD')
        }
      }
      if (objNewData.jam_masuk) {
        const tipeJamMasuk = typeof objNewData.jam_masuk
        if (tipeJamMasuk === 'object') {
          objNewData.jam_masuk = moment(objNewData.jam_masuk).format('HH:mm:ss')
        }
      }
      if (objNewData.jam_keluar) {
        const tipeJamKeluar = typeof objNewData.jam_keluar
        if (tipeJamKeluar === 'object') {
          objNewData.jam_keluar = moment(objNewData.jam_keluar).format('HH:mm:ss')
        }
      }
      const payload = {
        status,
        newData: objNewData,
      }
      this.$emit('on-change-detil-log', payload)
      this.newData = this.clearAllData()
      this.detilAbsensi = this.clearAllData()
    },
    cancelChangeDetilLog() {
      this.newData = this.clearAllData()
      this.detilAbsensi = this.clearAllData()
      this.$emit('on-cancel-detil-log', 'cancel')
    },
  },
}
</script>
